import { Chip } from '@mui/material'
import { styled } from '@mui/system'

export const ChipSimple = styled(Chip)({
  borderRadius: '6px',
})

export const ChipKeywords = styled(Chip)(({ textColor, randomColor }) => ({
  borderRadius: '6px',
  marginRight: '8px',
  marginBottom: '8px',
  color: textColor,
  backgroundColor: randomColor,
  textOverflow: 'ellipsis',
  height: 'auto',
  minHeight: '25px',
}))
