/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import contactPoint from '../config/templates/contactPoint.json'
import creator from '../config/templates/creator.json'
import dataset from '../config/templates/dataset.json'
import distribution from '../config/templates/distribution.json'
import foreignKey from '../config/templates/foreignKey.json'
import itemsCount from '../config/templates/itemsCount.json'
import publisher from '../config/templates/publisher.json'
import publisherDpo from '../config/templates/publisherDpo.json'
import qualityFeatures from '../config/templates/qualityFeatures.json'
import tables from '../config/templates/table.json'
import tableSchema from '../config/templates/tableSchema.json'
import variables from '../config/templates/variable.json'

const jp = require('jsonpath')

/**
 * Recursively nests the `csvw_columns` fields in a `csvw_tableSchema` object.
 * Replaces all `:` in the keys with `_`.
 * @param {any} data - The data to process.
 * @returns {any} - The processed data with nested `csvw_columns`.
 */
function nestCsvwColumnsAndReplaceKeys(data) {
  if (Array.isArray(data)) {
    return data.map(nestCsvwColumnsAndReplaceKeys)
  }
  if (typeof data === 'object' && data !== null) {
    if ('csvw_columns' in data) {
      const { csvw_columns, ...rest } = data
      const updatedRest = Object.fromEntries(
        Object.entries(rest).map(([key, value]) => [
          key.replace(':', '_'),
          nestCsvwColumnsAndReplaceKeys(value),
        ])
      )
      return {
        ...updatedRest,
        csvw_tableSchema: { csvw_columns },
      }
    }
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key.replace(':', '_'),
        nestCsvwColumnsAndReplaceKeys(value),
      ])
    )
  }
  return data
}

/**
 * Creates a deepcopy of an object
 * @param {any} data - The object to copy.
 * @returns {any} - The deepcopy of the object.
 */
function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj))
}

/**
 * Get the previous level in a JSON path.
 * @param {Array} jsonPath - The JSON path.
 * @returns {string|null} - The previous level or '$' if it's the root, or null if not found.
 */
function getPreviousLevel(jsonPath) {
  const lastIndex = jsonPath.length - 1
  if (lastIndex === 0) {
    return '$'
  }
  for (let index = lastIndex - 1; index >= 0; index -= 1) {
    if (typeof jsonPath[index] !== 'number') {
      return jsonPath[index]
    }
  }
  return null
}

/**
 * Remove keys from the object that are not equal to "@id".
 * @param {Object} obj - The object from which non-id keys should be removed.
 */
function removeNonIdKeys(obj) {
  Object.keys(obj).forEach((key) => {
    if (key !== '@id') {
      delete obj[key]
    }
  })
}

export default function parseFromOutput(data) {
  const templates = {
    dct_creator: {
      uri: 'creator',
      field: null,
      data: creator,
    },
    dct_publisher: {
      uri: 'publisher',
      field: null,
      data: publisher,
    },
    hdh_publisherDpo: {
      uri: 'publisherDpo',
      field: null,
      data: publisherDpo,
    },
    dcat_contactPoint: {
      uri: 'contactPoint',
      field: null,
      data: contactPoint,
    },
    dcat_distribution: {
      uri: 'distribution%23{NAME}',
      field: 'adms_identifier',
      data: distribution,
    },
    csvw_tables: {
      uri: 'tables%23{NAME}',
      field: 'dct_title',
      data: tables,
    },
    csvw_tableSchema: { uri: 'tableSchema', field: null, data: tableSchema },
    csvw_columns: {
      uri: 'columns%23{NAME}',
      field: 'csvw_name',
      data: variables,
    },
    hdh_itemsCount: {
      uri: 'itemsCount',
      field: null,
      data: itemsCount,
    },
    hdh_qualityFeatures: {
      uri: 'qualityFeatures',
      field: null,
      data: qualityFeatures,
    },
    csvw_foreignKey: {
      uri: 'foreignKey',
      field: null,
      data: foreignKey,
    },
  }
  const BASE_URL = `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_FUSEKI_PORT}`
  const ENDPOINT = process.env.REACT_APP_FUSEKI_ENDPOINT

  let GRAPH = ''
  const out = nestCsvwColumnsAndReplaceKeys(data)
  const graph = [dataset]
  /**
   * Process the root level of an element and update the graph accordingly.
   * @param {Object} element - The element to process.
   */
  const processRootLevel = (element) => {
    const { path, value } = element
    const type = typeof element.value
    const field = path[1].replace('_', ':')

    if (type === 'string') {
      graph[0][field] = value
      if (field === 'skos:altLabel') {
        GRAPH = value
        const uri = '{BASE_URL}/{ENDPOINT}/catalog/record/dataset%23{GRAPH}'
          .replace('{BASE_URL}', BASE_URL)
          .replace('{ENDPOINT}', ENDPOINT)
          .replace('{GRAPH}', GRAPH)
        graph[0]['@id'] = uri
      }
    }

    if (Array.isArray(value)) {
      graph[0][field] = value
    }
  }

  /**
   * Build the URI for a given string path, URI, path, path index, and level.
   * @param {string} stringPath - The string path.
   * @param {string} uri - The URI.
   * @param {Array} path - The path.
   * @param {number|undefined} pathindex - The path index.
   * @param {string} level - The level.
   * @returns {Object} - An object containing the updated string path and URI.
   */
  const buildUri = (stringPath, uri, path, pathindex, level) => {
    let updatedStringPath = stringPath
    let updatedUri = uri
    const previousPath = getPreviousLevel(path)

    if (previousPath !== '$' || pathindex !== undefined) {
      if (pathindex === undefined) {
        pathindex = 0
      }
      const combinedPath = path.concat(pathindex)
      jp.stringify(combinedPath)
        .split('.')
        .forEach((element) => {
          if (element !== '$') {
            updatedStringPath = updatedStringPath.concat(`..${element}`)
            const extractedElement = element.split('[')[0]
            const subfield = templates[extractedElement].field
            const id = jp.value(out, `${updatedStringPath}..${subfield}`)
            updatedUri = updatedUri.concat(
              '/',
              templates[extractedElement].uri.replace('{NAME}', id)
            )
          }
        })
    } else {
      updatedUri = updatedUri.concat('/', templates[level].uri)
    }
    if (previousPath === '$') {
      if (!jp.value(out, path).length) {
        graph[0][level.replace('_', ':')] = { '@id': updatedUri }
      }
    }
    if (level === 'csvw_columns') {
      // needed to fix inconsistent nesting  with tableSchema
      updatedUri = updatedUri.replace('/tableSchema', '')
    }
    return updatedUri
  }

  /**
   * Process the level by updating the template with values from the level.
   * @param {Object} allFromLevel - The values from the level.
   * @param {Object} newTemplate - The new template.
   * @param {string} level - The level.
   * @param {Array} path - The path.
   * @param {number} pathindex - The path index.
   * @returns {Object} - The updated template.
   */
  const processLevel = (allFromLevel, newTemplate, level, path, pathindex) => {
    const stringPath = '$'
    const uri = '{BASE_URL}/{ENDPOINT}/catalog/record/dataset%23{GRAPH}'
      .replace('{BASE_URL}', BASE_URL)
      .replace('{ENDPOINT}', ENDPOINT)
      .replace('{GRAPH}', GRAPH)
    const updatedUri = buildUri(stringPath, uri, path, pathindex, level)
    if (typeof allFromLevel !== 'string') {
      allFromLevel['@id'] = updatedUri
      Object.entries(allFromLevel).forEach(([key, value]) => {
        const field = key.replace('_', ':')
        newTemplate[field] = value
      })
      return newTemplate
    }
    return null;
  }

  /**
   * Process the template level by extracting values from the formData and generating corresponding graph entries.
   * @param {string} level - The template level.
   */
  const processTemplateLevel = (level) => {
    const allFromLevel = jp.nodes(out, `$..${level}`)

    allFromLevel.forEach((item) => {
      const { value, path } = item

      if (Array.isArray(value)) {
        value.forEach((element, index) => {
          graph.push(
            processLevel(
              element,
              deepCopy(templates[level].data),
              level,
              path,
              index
            )
          )
        })
      } else {
        graph.push(
          processLevel(
            value,
            deepCopy(templates[level].data),
            level,
            path,
            undefined
          )
        )
      }
    })
  }

  const rootLevel = jp.nodes(out, '*')
  rootLevel.forEach(processRootLevel)
  Object.keys(templates).forEach((level) => {
    processTemplateLevel(level)
  })

  const transformKey = (key) => key.replace(':', '_')
  graph.forEach((element) => {
    Object.keys(element).forEach((key) => {
      const transformedKey = transformKey(key)
      if (transformedKey in templates) {
        const value = element[key]
        if (Array.isArray(value)) {
          value.forEach((subElement) => {
            removeNonIdKeys(subElement)
          })
        } else if (typeof value === 'object' && value !== null) {
          removeNonIdKeys(value)
        }
      }
    })
  })
  return graph
}
