import HelpOutlineSharpIcon from '@mui/icons-material/HelpOutlineSharp'
import {
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import TranslatorFile from '../../config/inv_formats.json'
import { ChipKeywords } from '../../styles/Chips'

export function renderCellContent(data, type) {
  const newObject = Object.prototype.hasOwnProperty.call(TranslatorFile, data)
    ? TranslatorFile[data]
    : data
  if (type === 'url') {
    return (
      <Typography
        sx={{ whiteSpace: 'pre-line' }}
        component="a"
        href={newObject}
        data-test={`CellContent-url-${newObject}`}
      >
        {newObject}
      </Typography>
    )
  }
  if (type === 'email') {
    return (
      <Typography
        sx={{ whiteSpace: 'pre-line' }}
        component="a"
        href={`mailto:${newObject}`}
        data-test={`CellContent-email-${newObject}`}
      >
        {newObject}
      </Typography>
    )
  }
  return (
    <Typography
      sx={{ whiteSpace: 'pre-line' }}
      data-test={`CellContent-Typography-${newObject}`}
    >
      {newObject}
    </Typography>
  )
}

export function renderMultipleComponent(type, data, isMultiple) {
  if (isMultiple && Array.isArray(data) && data.length > 1) {
    return (
      <Grid
        item
        sx={{
          flexWrap: 'wrap',
          p: 0.5,
          m: 0,
        }}
      >
        {data.map((element) => (
          <ChipKeywords
            key={`keywordDoc-${element}`}
            size="small"
            label={renderCellContent(element, type)}
            color="secondary"
            data-test={`CellContent-Chip-${element}`}
          />
        ))}
      </Grid>
    )
  }
  return renderCellContent(data, type)
}

export default function GenericCard({ category, metadata, showEmpty }) {
  const allEmpty = showEmpty
    ? false
    : metadata.object
        .flat()
        .every((element) => element === null || element === '')
  if (['', 'Header'].includes(category)) {
    return null
  }

  if (!allEmpty) {
    return (
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ borderRadius: '12px' }}
      >
        {category === 'Général' ? null : (
          <Toolbar>
            <Typography variant="h5">{category}</Typography>
          </Toolbar>
        )}
        <Table size="small">
          <TableBody>
            {metadata.object.map((data, index) => {
              if (
                (metadata.shown[index] &&
                  !data.every(
                    (element) => element === null || element === ''
                  )) ||
                showEmpty
              ) {
                return (
                  <TableRow
                    key={metadata.fr_field_name[index]}
                    data-test={`TableRow-${metadata.fr_field_name[index]}`}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        minWidth: '15rem',
                        maxWidth: '15rem',
                        overflowWrap: 'break-word',
                      }}
                    >
                      <Stack direction="row" spacing={1}>
                        <Typography
                          sx={{ whiteSpace: 'pre-line' }}
                          data-test={`TableCell-Typography-${metadata.fr_field_name[index]}`}
                        >
                          {metadata.fr_field_name[index]}
                        </Typography>
                        {metadata.helper[index] ? (
                          <Tooltip
                            title={metadata.helper[index]}
                            sx={{ color: '#612491' }}
                            data-test={`TableCell-Tooltip-${metadata.fr_field_name[index]}`}
                          >
                            <HelpOutlineSharpIcon />
                          </Tooltip>
                        ) : null}
                      </Stack>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        minWidth: '15rem',
                        maxWidth: '15rem',
                        overflowWrap: 'break-word',
                      }}
                    >
                      {renderMultipleComponent(
                        metadata.type[index],
                        data,
                        metadata.multiple[index]
                      )}
                    </TableCell>
                  </TableRow>
                )
              }
              return null
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

GenericCard.propTypes = {
  category: PropTypes.string.isRequired,
  metadata: PropTypes.shape.isRequired,
  showEmpty: PropTypes.bool.isRequired,
}
