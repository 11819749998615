import { createContext } from 'react'

export const TabSwitcherContext = createContext({
  graph: null,
  distribution: null,
  table: null,
  variable: null,
  sample: null,
  database: null,
  dataset: null,
  dataService: null,
  tabState: null,
  depth: null,
  listData: null,
  secondaryListData: null,
  metadata: null,
  showEmpty: null,
})

export default TabSwitcherContext
