import { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_MAIN_API_URL;

const SettingsContext = createContext(null);

export function SettingsProvider({ children }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchSettingsAndLogo = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/config/settings`);
        const logo = await axios.get(`${BASE_URL}/config/logo`, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        setData({ ...response.data, logo: logo.data.image });
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setData({ error: 'Failed to load data' });
      }
    };

    fetchSettingsAndLogo();
  }, []);

  return (
    data 
    ? (
      <SettingsContext.Provider value={data}>
        {children}
      </SettingsContext.Provider>
    )
    : null
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useSettings = () => useContext(SettingsContext);

