import HomeIcon from '@mui/icons-material/Home'
import { Link, Stack, Tooltip } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import siteLogo from '../assets/site_logo.png'
import { ButtonText } from '../styles/Buttons'
import { useSettings } from '../utils/settings'

export default function ButtonAppBar() {
  const settings = useSettings();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ boxShadow: 'none' }}>
        <Toolbar>
          <Box
            component={Link}
            href="/"
            data-test="LinkLogo"
            sx={{
              padding: '12px',
              paddingLeft: '0',
              maxWidth: '85.55px',
              maxHeight: '58.61px',
            }}
          >
            <Box
              component="img"
              data-test="logo"
              alt="Health Data Hub Logo"
              src={settings?.logo ?? siteLogo}
              sx={{
                paddingLeft: '0',
                maxWidth: '85.55px',
                maxHeight: '58.61px',
              }}
            />
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
              paddingRight: '12px',
            }}
          >
            Catalogue de métadonnées
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" spacing={1}>
            <Stack direction="row">
              {window.location.pathname === '/' ? null : (
                <Tooltip title="Page d'accueil">
                  <ButtonText
                    color="inherit"
                    href="/"
                    startIcon={<HomeIcon sx={{ paddingLeft: '8px' }} />}
                    data-test="HomeButton"
                  >
                    Accueil
                  </ButtonText>
                </Tooltip>
              )}
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      <Toolbar variant="dense" />
    </Box>
  )
}
