import DeleteIcon from '@mui/icons-material/Delete'
import { Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { getCards } from '../../lib/axios'
import { ChipSimple } from '../../styles/Chips'
import getGraphs from '../../utils/getGraphs'
import { CardsLoaded } from '../../utils/utils'

export default function SelectedFilters({
  checked,
  setChecked,
  setInput,
  setCards,
  setLoading,
}) {
  const flat = Object.values(checked).flat(1)

  const handlClick = async () => {
    setLoading(true)
    const newChecked = {}
    Object.keys(checked).forEach((key) => {
      newChecked[key] = []
    })
    setChecked(newChecked)
    const { value } = document.getElementById('searchBar')
    if (value) {
      const graphs = await getGraphs(value, newChecked)
      setCards(await getCards(graphs))
    } else {
      setCards(await getCards())
      setInput('')
    }
    if (value.length > 0) {
      setInput(`input=${document.getElementById('searchBar').value}&`)
    }
    CardsLoaded(setLoading)
  }

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        flexFlow: 'row wrap',
        overflowY: 'auto',
        wordBreak: 'break-word',
        flexWrap: 'wrap',
      }}
    >
      {flat.map((val, index) =>
        index === 0 ? (
          <Stack
            key={val}
            direction="row"
            item
            spacing={1}
            sx={{ marginBottom: '8px' }}
          >
            <Typography>Filtres appliqués :</Typography>
            <ChipSimple size="small" label={val} />
          </Stack>
        ) : (
          <Stack key={val} item sx={{ marginTop: '8px' }}>
            <ChipSimple size="small" label={val} />
          </Stack>
        )
      )}
      {flat.length ? (
        <ChipSimple
          size="small"
          icon={<DeleteIcon />}
          clickable
          label="tout effacer"
          color="error"
          variant="outlined"
          onClick={handlClick}
        />
      ) : null}
    </Stack>
  )
}

SelectedFilters.propTypes = {
  checked: PropTypes.shape.isRequired,
  setChecked: PropTypes.func.isRequired,
  setInput: PropTypes.func.isRequired,
  setCards: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
}
