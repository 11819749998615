import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

export default function MarkdownText({ markdownText }) {
  return (
    <Typography>
      <Markdown remarkPlugins={[remarkGfm]}>{markdownText}</Markdown>
    </Typography>
  )
}

MarkdownText.propTypes = {
  markdownText: PropTypes.string.isRequired,
}
