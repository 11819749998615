import ExpandLessSharpIcon from '@mui/icons-material/ExpandLessSharp'
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp'
import {
  Box,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CardSimple from '../styles/Cards'
import { ChipKeywords, ChipSimple } from '../styles/Chips'
import { getTranslated } from '../utils/utils'
import DownloadMenu from './DownloadMenu'
import Score from './Score'

/**
 * checks if element is overflowing
 * @param {*} element
 * @returns {boolean}
 */
function isOverflown(element) {
  if (element) {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    )
  }
  return false
}

export default function SummaryCard({ metadata, input }) {
  const [openedDescription, setOpenedDescription] = useState(false)
  const [overflown, setOverflown] = useState()
  const { lastUpdated } = metadata // disabled
  const { tables } = metadata // disabled
  const { variables } = metadata // disabled
  const {
    graph_id: graphID,
    trimmed_subject: trimmedSubject,
    identifier,
    description,
    creator_name: creatorName,
    keywords,
    languages,
    formats,
  } = metadata
  const url = new URL(graphID.value)
  const score = <Score score="notRated" />
  const documentLink = () => {
    if (input) {
      return `/dataset?graph=${url.hostname}&${input}`
    }
    return `/dataset?graph=${url.hostname}&dataset=${trimmedSubject.value}`
  }

  const handleCLick = () => {
    const element = document.getElementById(
      `descriptionDoc-${identifier.value}`
    )
    if (openedDescription === false) {
      element.style.maxHeight = '100%'
      element.style.WebkitMaskImage = 'linear-gradient(180deg, #000 60%, black)'
      setOpenedDescription(true)
    } else {
      element.style.maxHeight = '10rem'
      element.style.WebkitMaskImage =
        'linear-gradient(180deg, #000 60%, transparent)'
      setOpenedDescription(false)
    }
  }
  useEffect(() => {
    const element = document.getElementById(
      `descriptionDoc-${identifier.value}`
    )
    const result = isOverflown(element)
    setOverflown(result)
    if (!result) {
      element.style.WebkitMaskImage = 'linear-gradient(180deg, #000 60%, black)'
    }
  }, [identifier.value, overflown])

  return (
    <Box sx={{ width: '100%' }}>
      <CardSimple variant="outlined" data-test={`CardDoc-${identifier.value}`}>
        <CardActionArea
          LinkComponent={Link}
          data-test={`CardDocHeader-${identifier.value}`}
          disableRipple
          to={documentLink()}
        >
          <CardHeader
            title={
              <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  variant="h4"
                  data-test={`titleDoc-${identifier.value}`}
                >
                  {identifier.value}
                </Typography>
                <Stack direction="row" alignItems="center">
                  {creatorName?.value ? (
                    <Typography sx={{ fontStyle: 'italic' }}>
                      par&nbsp;
                    </Typography>
                  ) : null}
                  <Typography
                    data-test={`ownerDoc-${creatorName?.value}`}
                    variant="h6"
                  >
                    {creatorName?.value}
                  </Typography>
                </Stack>
                <Stack direction="row">
                  {lastUpdated ? (
                    <Typography sx={{ fontStyle: 'italic' }}>
                      dernière mise a jour le : &nbsp;
                    </Typography>
                  ) : null}
                  <Typography
                    data-test={`lastUpdatedDoc-${lastUpdated}`}
                    variant="h6"
                  >
                    {lastUpdated}
                  </Typography>
                </Stack>
                {score}
              </Stack>
            }
          />
        </CardActionArea>
        <Divider />
        <CardContent>
          <Stack spacing={2} divider={<Divider flexItem />}>
            {keywords?.value.length ? (
              <Grid
                item
                sx={{
                  flexWrap: 'wrap',
                  p: 0.5,
                  m: 0,
                }}
                data-test={`keywordsListDoc-${identifier.value}`}
              >
                {keywords?.value.split(',').map((data) => (
                  <ChipKeywords
                    key={`keywordDoc-${data}`}
                    size="small"
                    label={getTranslated(data)}
                    color="secondary"
                    data-test={`keywordDoc-${data}`}
                  />
                ))}
              </Grid>
            ) : null}
            <Stack>
              <Typography
                id={`descriptionDoc-${identifier.value}`}
                display="inline"
                sx={{
                  whiteSpace: 'pre-line',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  minHeight: '5rem',
                  maxHeight: '10rem',
                  position: 'relative',
                  WebkitMaskImage:
                    'linear-gradient(180deg, #000 60%, transparent)',
                }}
                data-test={`descriptionDoc-${identifier.value}`}
              >
                {description?.value}
              </Typography>
              <Box>
                {overflown ? (
                  <IconButton
                    onClick={handleCLick}
                    data-test={`overflowDescriptionDocButton-${identifier.value}`}
                  >
                    {openedDescription ? (
                      <ExpandLessSharpIcon />
                    ) : (
                      <ExpandMoreSharpIcon />
                    )}
                  </IconButton>
                ) : null}
              </Box>
            </Stack>
          </Stack>
        </CardContent>
        <Divider />
        <CardActions>
          <Container maxWidth={false}>
            <Stack direction="row" justifyContent="space-between">
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                divider={<Divider orientation="vertical" flexItem />}
              >
                {languages?.value ? (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <ChipSimple
                      key={`languageDoc-${languages.value.split('/').reverse()[0]}`}
                      label={languages.value.split('/').reverse()[0]}
                      size="small"
                      data-test={`languageDoc-${languages.value.split('/').reverse()[0]}`}
                    />
                  </Stack>
                ) : null}
                {formats?.value ? (
                  <Stack direction="row" spacing={1} alignItems="center">
                    {formats.value.split(',').map((format) => (
                      <ChipSimple
                        key={`formatDoc-${format}`}
                        label={getTranslated(format)}
                        size="small"
                        data-test={`formatDoc-${format}`}
                      />
                    ))}
                  </Stack>
                ) : null}
                {tables && variables ? (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <ChipSimple
                      label={`${tables} Tables`}
                      size="small"
                      data-test={`tablesDoc-${tables}`}
                    />
                    <ChipSimple
                      label={`${variables} Variables`}
                      size="small"
                      data-test={`variablesDoc-${variables}`}
                    />
                  </Stack>
                ) : null}
              </Stack>
              <Stack direction="row" spacing={1}>
                <DownloadMenu identifier={url.hostname} />
              </Stack>
            </Stack>
          </Container>
        </CardActions>
      </CardSimple>
    </Box>
  )
}

SummaryCard.defaultProps = { input: null }

SummaryCard.propTypes = {
  metadata: PropTypes.shape.isRequired,
  input: PropTypes.string,
}
