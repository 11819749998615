import { Container } from '@mui/material'
import PropTypes from 'prop-types'
import { Page } from '../styles/Styles'
import ButtonAppBar from './AppBar'
import MarkdownText from './MarkdownText'

export default function MarkdownPage({ markdownText }) {
  return (
    <Page maxWidth={false}>
      <ButtonAppBar />
      <Container maxWidth={false} sx={{ marginTop: '1%' }}>
        <MarkdownText markdownText={markdownText} />
      </Container>
    </Page>
  )
}

MarkdownPage.propTypes = {
  markdownText: PropTypes.string.isRequired,
}
