import ExpandLessSharpIcon from '@mui/icons-material/ExpandLessSharp'
import { useEffect, useState } from 'react'
import ButtonAppBar from './components/AppBar'
import Home from './pages/Home'
import { FABScrollUp } from './styles/Buttons'
import { Page } from './styles/Styles'

export default function App() {
  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.scrollY
    setScrollPosition(position)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <Page maxWidth={false}>
      <ButtonAppBar />
      <Home />
      {scrollPosition > 100 ? (
        <FABScrollUp
          size="medium"
          color="secondary"
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
          }}
        >
          <ExpandLessSharpIcon />
        </FABScrollUp>
      ) : null}
    </Page>
  )
}
