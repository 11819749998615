import { Box, Container, Grid, Typography } from '@mui/material'
import { useEffect } from 'react'
import unauthorized from '../assets/unauthorized.png'
import ButtonAppBar from '../components/AppBar'
import { Page } from '../styles/Styles'

export default function Unauthorized() {
  useEffect(() => {
    document.title = '401 - Unauthorized'
  }, [])

  return (
    <Page maxWidth={false}>
      <ButtonAppBar />
      <Container maxWidth={false} sx={{ marginTop: '2%' }}>
        <Grid
          container
          spacing={2}
          sx={{ minHeight: '70vh' }}
          direction="column"
          alignItems="center"
          display="flex"
          justifyContent="center"
        >
          <Box
            component="img"
            sx={{
              height: 80,
              width: 80,
            }}
            alt={
              <a
                href="https://www.flaticon.com/free-icons/unattended"
                title="unattended icons"
              >
                Unattended icons created by Hazicon - Flaticon
              </a>
            }
            src={unauthorized}
          />

          <Typography
            sx={{ fontWeight: 'bold' }}
          >
            Accès non autorisé
          </Typography>
        </Grid>
      </Container>
    </Page>
  )
}
