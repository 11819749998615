import { Alert, Snackbar } from '@mui/material'
import PropTypes from 'prop-types'

export default function Snack(props) {
  const { open } = props
  const { message } = props
  const { type } = props
  const { setOpen } = props
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      data-test="snack"
    >
      <Alert
        onClose={handleClose}
        severity={type}
        sx={{ width: '100%' }}
        data-test={`alert${type}`}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

Snack.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
}
