import { createTheme } from '@mui/material'

export default function getTheme(settings) {
  return (
    createTheme({
      palette: {
        type: 'light',
        primary: {
          main: settings?.primaryColor ?? '#036287',
        },
        secondary: {
          main: settings?.secondaryColor ?? '#5DEEE3',
        },
        text: {
          primary: '#1D1D1B',
          secondary: '#06303A',
          disabled: '#9e9e9e',
        },
        success: {
          main: '#612491',
        },
        info: {
          main: '#612491',
        },
      },
      typography: {
        fontFamily: [settings?.fontFamily, 'sans-serif'].join(','),
        h1: {
          fontWeight: 700,
          fontSize: '6rem',
        },
        h2: {
          fontWeight: 700,
        },
        h3: {
          fontWeight: 700,
        },
        h5: {
          fontWeight: 700,
        },
      },
    })
  )
}

