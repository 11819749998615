/**
 * Creates a depth key object for navigating through a dataset hierarchy.
 *
 * @param {string} graph - The graph parameter.
 * @param {string} distribution - The distribution parameter.
 * @param {string} table - The table parameter.
 * @param {string} variable - The variable parameter.
 * @returns {Object} - The depth key object for navigation.
 */
export default function depthKeyCreator(graph, dataset, distribution, table, variable, dataService, sample, database) {
  return {
    '/dataset': {
      param: graph,
      text: 'Jeu de données',
      url: `/dataset?graph=${graph}&dataset=${dataset}`,
      nextUrl: `/distribution?graph=${graph}&dataset=${dataset}&distribution=${distribution}`,
      secondaryNextUrl: (valueSample, valueDatabase) => `/database?graph=${graph}&dataset=${dataset}&sample=${valueSample}&database=${valueDatabase}`,
      list: 'Listes des distributions',
      tabs: {
        Métadonnées: false,
        'Listes des distributions': false,
        'Base de données': false,
        // Arborescence: true,
      },
    },
    '/database': {
      param: database,
      text: 'Base de données',
      url: `/database?graph=${graph}&dataset=${dataset}&sample=${sample}&database=${database}`,
      nextUrl: `/table?graph=${graph}&dataset=${dataset}&sample=${sample}&database=${database}&table=${table}`,
      list: 'Base de données',
      tabs: {
        Métadonnées: false,
        'Liste des tables': false,
      },
    },
    '/distribution': {
      param: distribution,
      text: 'Distribution',
      url: `/distribution?graph=${graph}&dataset=${dataset}&distribution=${distribution}`,
      nextUrl: `/data-service?graph=${graph}&dataset=${dataset}&distribution=${distribution}&data_service=${dataService}`,
      list: "Liste des distributions",
      tabs: {
        Métadonnées: false,
        "Service d'accès": false,
      },
    },
    '/data-service': {
      param: dataService,
      text: "Service d'accès",
      url: `/data-service?graph=${graph}&dataset=${dataset}&distribution=${distribution}&data_service=${dataService}`,
      list: null,
      tabs: null,
    },
    '/table': {
      param: table,
      text: 'Table',
      url: `/table?graph=${graph}&dataset=${dataset}&sample=${sample}&database=${database}&table=${table}`,
      nextUrl: `/variable?graph=${graph}&dataset=${dataset}&sample=${sample}&database=${database}&table=${table}&variable=${variable}`,
      list: 'Listes des variables',
      tabs: {
        Métadonnées: false,
        'Listes des variables': false,
        'Clés étrangères': false
      },
    },
    '/variable': {
      param: variable,
      text: 'Variable',
      url: `/variable?graph=${graph}&dataset=${dataset}&sample=${sample}&database=${database}&table=${table}&variable=${variable}`,
      tabs: null,
      list: null,
    },
  }
}
