import FilterAltOffSharpIcon from '@mui/icons-material/FilterAltOffSharp'
import FilterAltSharpIcon from '@mui/icons-material/FilterAltSharp'
import {
  CardHeader,
  CircularProgress,
  Stack,
  ToggleButton,
  Tooltip,
} from '@mui/material'
import PropTypes from 'prop-types'
import DownloadMenu from '../../components/DownloadMenu'

export default function Title({
  text,
  name,
  graphid,
  showEmpty,
  setShowEmpty,
}) {
  return (
    <CardHeader
      data-test={`CardHeader-${text}-${name}`}
      title={name ? `${text} : ${name}` : <CircularProgress />}
      titleTypographyProps={{
        variant: 'h4',
        fontWeight: 'bold',
        'data-test': `Typography-${text}-${name}`,
      }}
      action={
        <Stack direction="row" spacing={1}>
          <Tooltip
            title={
              showEmpty ? 'Cacher les champs vides' : 'Montrer les champs vides'
            }
          >
            <ToggleButton
              value="check"
              size="small"
              sx={{ borderRadius: '16px' }}
              selected={showEmpty}
              onChange={() => {
                setShowEmpty(!showEmpty)
              }}
              data-test="ToggleEmptyFields"
            >
              {showEmpty ? <FilterAltSharpIcon /> : <FilterAltOffSharpIcon />}
            </ToggleButton>
          </Tooltip>
          <DownloadMenu indentifier={graphid} />
        </Stack>
      }
    />
  )
}

Title.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  graphid: PropTypes.string.isRequired,
  showEmpty: PropTypes.bool.isRequired,
  setShowEmpty: PropTypes.func.isRequired,
}
