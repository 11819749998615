import { materialCells, materialRenderers } from '@jsonforms/material-renderers'
import { JsonForms } from '@jsonforms/react'
import { DialogActions, DialogContent } from '@mui/material'
import Box from '@mui/material/Box'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import { useEffect, useState } from 'react'
import { FadeLoader } from 'react-spinners'
import Snack from '../../components/Snack'
import ListInputs, {
  ListMetadata,
  PostRelationships,
  SendForm,
  SendFormUpdate,
} from '../../lib/axios'
import { ButtonCaps } from '../../styles/Buttons'
import { Text, Validator } from '../../styles/Styles'
import parseFromOutput from '../../utils/formParser'

dayjs.locale('fr')

export default function Form() {
  const [, setSession] = useState()
  const [, setLogoutUrl] = useState()
  const [, setPermissions] = useState()
  const [, setRole] = useState()
  const [schema, setSchema] = useState()
  const [uischema, setUISchema] = useState()
  const [data, setData] = useState({})
  const [recupData, setRecupData] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)
  const [openSnackError, setOpenSnackError] = useState(false)
  const [error, setError] = useState('')
  const addMessage = 'Métadonnées insérées'
  const addType = 'success'
  const errorType = 'error'
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const handleOpen = () => {
    setOpenModal(true)
  }
  const handleClose = () => {
    setOpenModal(false)
  }

  /**
   * Asynchronously retrieves input structure and sets the schema and UI schema for a component.
   *
   * Note: Assumes the existence of the 'ListInputs' function and updates 'schema' and 'UISchema'.
   *
   * @returns {Promise<void>} - A promise that resolves after updating the state with input structure.
   */
  async function recupInputs() {
    const structure = await ListInputs()
    setSchema(structure[0])
    setUISchema(structure[1])
  }

  /**
   * Asynchronously retrieves metadata for a given document and updates the component state.
   *
   * Note: Assumes the existence of the 'ListMetadata' function and updates 'data', 'recupData', and 'loading'.
   *
   * @param {string} document - The document for which metadata is to be retrieved.
   * @returns {Promise<void>} - A promise that resolves after updating the state with metadata.
   */
  async function recupMetadata(document) {
    const metadata = await ListMetadata(document)
    setData(metadata)
    if (metadata !== {}) {
      setRecupData(true)
      setLoading(false)
    }
  }
  const Add = async (document) => {
    const reformatedOutput = parseFromOutput(document)
    let res = {}
    if (recupData === true) {
      res = await SendFormUpdate(document['skos:altLabel'], reformatedOutput)
    } else {
      res = await SendForm(document['skos:altLabel'], reformatedOutput)
    }
    if (res.status === 200) {
      setOpenSnack(true)
      PostRelationships({
        relation: 'parents',
        object: document['skos:altLabel'],
        namespace: 'File',
        subject_set: {
          namespace: 'Folder',
          relation: '',
          object: 'metadata',
        },
      })
      window.location.href = '/'
    } else if (res.response.status === 409) {
      setError('Ce jeu de données existe déjà.')
      setOpenSnackError(true)
    } else {
      setError("Un problème est apparu lors de l'insertion.")
      setOpenSnackError(true)
    }
  }
  useEffect(() => {
    recupInputs()
    const url = new URL(document.location.href)
    if (url.pathname === '/edit') {
      const urlParams = new URLSearchParams(window.location.search)
      const graph = urlParams.get('graph')
      recupMetadata(graph)
    } else {
      setLoading(false)
    }
  }, [])

  return (
    <div>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <FadeLoader color="#036287" />
        </Box>
      ) : (
        <Box>
          <JsonForms
            schema={schema}
            uischema={uischema}
            data={data}
            renderers={materialRenderers}
            cells={materialCells}
            onChange={({ data: newData }) => {
              setData(newData)
            }}
            validationMode="ValidateAndShow"
          />
          <ButtonCaps
            variant="contained"
            id="button"
            onClick={handleOpen}
            data-test="publishButton"
          >
            Publier
          </ButtonCaps>
          <Snack
            open={openSnack}
            message={addMessage}
            type={addType}
            setOpen={setOpenSnack}
          />
          <Validator open={openModal} onClose={handleClose}>
            <DialogContent>
              <Text>Avez-vous terminer de remplir vos métadonnées ?</Text>
            </DialogContent>
            <DialogActions>
              <ButtonCaps
                variant="contained"
                data-test="buttonYes"
                onClick={() => {
                  Add(data)
                }}
              >
                Oui
              </ButtonCaps>
              <ButtonCaps
                variant="contained"
                data-test="buttonNo"
                onClick={handleClose}
              >
                Non
              </ButtonCaps>
            </DialogActions>
          </Validator>
          <Snack
            open={openSnackError}
            message={error}
            type={errorType}
            setOpen={setOpenSnackError}
          />
        </Box>
      )}
    </div>
  )
}
