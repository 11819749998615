import { Box, Container, Grid, Typography } from '@mui/material'
import { useEffect } from 'react'
import noSearchResults from '../assets/searchNoResults.png'
import ButtonAppBar from '../components/AppBar'
import { Page } from '../styles/Styles'

export default function PageNotFound() {
  useEffect(() => {
    document.title = '404 - Page introuvable'
  }, [])

  return (
    <Page maxWidth={false}>
      <ButtonAppBar />
      <Container maxWidth={false} sx={{ marginTop: '2%' }}>
        <Grid
          container
          spacing={2}
          sx={{ minHeight: '70vh' }}
          direction="column"
          alignItems="center"
          display="flex"
          justifyContent="center"
        >
          <Box
            component="img"
            sx={{
              height: 80,
              width: 80,
            }}
            alt={
              <a
                href="https://www.flaticon.com/free-icons/no-results"
                title="no-results icons"
              >
                No-results icons created by Freepik - Flaticon
              </a>
            }
            src={noSearchResults}
          />

          <Typography
            sx={{ fontWeight: 'bold' }}
          >
            Page introuvable
          </Typography>
        </Grid>
      </Container>
    </Page>
  )
}
