import {
  faBarsStaggered,
  faFolderTree,
  faPersonDigging,
  faTags,
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tab, Tabs, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const tabIcons = {
  Historique: { icon: <FontAwesomeIcon icon={faBarsStaggered} />, upcoming: true },
  'Base de données': {
    icon: <FontAwesomeIcon icon={faBarsStaggered} />,
  },
  Métadonnées: { icon: <FontAwesomeIcon icon={faTags} /> },
  Arborescence: {
    icon: <FontAwesomeIcon icon={faFolderTree} />,
    upcoming: true,
  },
  'Listes des distributions': {
    icon: <FontAwesomeIcon icon={faBarsStaggered} />,
  },
  "Service d'accès": { icon: <FontAwesomeIcon icon={faBarsStaggered} /> },
  "Liste des tables": { icon: <FontAwesomeIcon icon={faBarsStaggered} /> },
  'Listes des variables': { icon: <FontAwesomeIcon icon={faBarsStaggered} /> },
  'Clés étrangères': { icon: <FontAwesomeIcon icon={faBarsStaggered} /> },
}

export default function MetadataTabs({
  depth,
  depthKey,
  tabState,
  setTabState,
}) {
  const handleChange = (event, newValue) => {
    setTabState(newValue)
  }
  if (tabState !== undefined && depthKey[depth].tabs) {
    return (
      <Tabs value={tabState} onChange={handleChange} centered>
        {depthKey[depth].tabs
          ? Object.entries(depthKey[depth].tabs).map(
              ([tabElement, isDisabled]) => (
                <Tab
                  disabled={isDisabled}
                  key={tabElement}
                  sx={{ textTransform: 'none' }}
                  icon={
                    tabIcons[tabElement].upcoming ? (
                      <FontAwesomeIcon icon={faPersonDigging} />
                    ) : (
                      tabIcons[tabElement].icon
                    )
                  }
                  iconPosition="start"
                  label={<Typography variant="h6">{tabElement}</Typography>}
                  data-test={`Tab-${tabElement}`}
                />
              )
            )
          : null}
      </Tabs>
    )
  }
  return null
}
MetadataTabs.propTypes = {
  depth: PropTypes.string.isRequired,
  depthKey: PropTypes.shape.isRequired,
  tabState: PropTypes.number.isRequired,
  setTabState: PropTypes.func.isRequired,
}
