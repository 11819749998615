import {
  BalanceSharp,
  EmailSharp,
  InfoSharp,
  LockSharp,
} from '@mui/icons-material'
import {
  BottomNavigation,
  Dialog,
  BottomNavigationAction,
} from '@mui/material'
import { useState } from 'react'
import Contact from './Contact'
import { useSettings } from '../utils/settings'

export default function Footer() {
  const settings = useSettings();
  const [openContactDialog, setOpenContactDialog] = useState(false)
  const handleOpenContactDialog = () => {
    setOpenContactDialog(true)
  }

  const handleCloseContactDialog = () => {
    setOpenContactDialog(false)
  }
  return (
    <BottomNavigation
      showLabels
      sx={{
        padding: '16px',
        backgroundColor: 'transparent',
        textAlign: 'center',
      }}
    >
      <BottomNavigationAction
        data-test="CompanyInfo"
        href={settings?.aboutUsUrl}
        label="A propos de nous"
        icon={<InfoSharp />}
      />
      <BottomNavigationAction
        label="Contactez-nous"
        data-test="Contact"
        icon={<EmailSharp />}
        onClick={handleOpenContactDialog}
      />
      <Dialog open={openContactDialog} onClose={handleCloseContactDialog}>
        <Contact />
      </Dialog>
      <BottomNavigationAction
        data-test="LegalNotice"
        href="legal_notice"
        label="Mentions légales"
        icon={<BalanceSharp />}
      />
      <BottomNavigationAction
        data-test="PrivacyPolicy"
        href="privacy_policy"
        label="Politique de confidentialité"
        icon={<LockSharp />}
      />
      {/* <BottomNavigationAction
        data-test=""
        href={process.env.REACT_APP_ADMIN_URL}
        target="_blank"
        label="Admin"
        icon={<AdminPanelSettings />}
      /> */}
    </BottomNavigation>
  )
}
