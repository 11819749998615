import { Container, Dialog, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const font = `'${process.env.REACT_APP_FONT_NAME}', sans-serif`

const colorTexte = '#000000'

export const Page = styled(Container)(() => ({
  // maxWidth: false,
  // disableGutters: true,
  backgroundColor: '#F2F7F9',
  minHeight: window.innerHeight - 100,
  minWidth: window.innerWidth - 100,
}))

export const Text = styled(Typography)(() => ({
  marginBottom: '10px',
  fontFamily: font,
  color: colorTexte,
}))

export const TitlePageGuide = styled(Typography)(() => ({
  color: colorTexte,
  textAlign: 'center',
  fontFamily: font,
  fontSize: 30,
  fontWeight: 'bold',
}))

export const TitleGuide = styled(Typography)(() => ({
  color: colorTexte,
  textAlign: 'left',
  fontFamily: font,
  fontSize: 24,
  fontWeight: 'bold',
  marginTop: 10,
}))

export const SubtitleGuide = styled(Typography)(() => ({
  color: colorTexte,
  textAlign: 'left',
  fontFamily: font,
  fontSize: 18,
  fontWeight: 'bold',
  marginTop: 10,
}))

export const TexteGuide = styled(Typography)(() => ({
  color: colorTexte,
  fontFamily: font,
  fontSize: 14,
  textAlign: 'justify',
  marginTop: 10,
  marginBottom: 10,
}))

export const Validator = styled(Dialog)(() => ({
  position: 'absolute',
}))
