import { Button, Fab, IconButton } from '@mui/material'
import { styled } from '@mui/system'

const font = `'${process.env.REACT_APP_FONT_NAME}', sans-serif`

export const ButtonSimple = styled(Button)({
  borderRadius: '30px',
  boxShadow: 'none',
  textTransform: 'none',
  fontFamily: font,
})

export const ButtonText = styled(Button)({
  borderRadius: '30px',
  paddingRight: '16px',
  boxShadow: 'none',
  textTransform: 'none',
  fontFamily: font,
})

export const ButtonCaps = styled(Button)({
  fontFamily: font,
  boxShadow: 'none',
})

export const IconButtonSimple = styled(IconButton)({ color: '#1d1d1b' })

export const FABScrollUp = styled(Fab)({
  boxShadow:
    'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
  borderRadius: '16px',
  margin: 0,
  top: 'auto',
  right: 20,
  bottom: 100,
  left: 'auto',
  position: 'fixed',
})
