import StarOutlinedIcon from '@mui/icons-material/StarOutlined'
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined'
import { Stack, Tooltip, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const metadataScore = {
  platinum: { stars: 4, color: '#594a86', text: 'Métadonnées Platine' },
  gold: { stars: 3, color: '#daa520', text: 'Métadonnées Or' },
  silver: { stars: 2, color: '#8e8e9a', text: 'Métadonnées Argent' },
  bronze: { stars: 1, color: '#cd7f32', text: 'Métadonnées Bronze' },
  notRated: { stars: 0, color: '#ccd0d3', text: 'Score non calculé' },
}

export default function Score(props) {
  const { score } = props
  const { stars, text, color } = metadataScore[score]
  return (
    <Tooltip
      title="Le score se rapporte à la quantité d'informations
      disponibles sur l'ensemble de données, et non à la qualité des
      l'ensemble de données."
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ color: { color } }}
        data-test={`scoreDoc-${score}`}
      >
        <Stack direction="row">
          {Array(4)
            .fill()
            .map((_, index) =>
              index < stars ? <StarOutlinedIcon /> : <StarOutlineOutlinedIcon />
            )}
        </Stack>
        <Typography variant="subtitle2">{text}</Typography>
      </Stack>
    </Tooltip>
  )
}

Score.propTypes = {
  score: PropTypes.PropTypes.oneOf([
    'platinum',
    'gold',
    'silver',
    'bronze',
    'notRated',
  ]).isRequired,
}
