import { createContext } from 'react'

export const HeaderContext = createContext({
  depth: null,
  graph: null,
  metadata: null,
  distribution: null,
  table: null,
  variable: null,
  tabState: null,
  setTabState: null,
  permissions: null,
  listData: null,
  showEmpty: null,
  setShowEmpty: null,
})

export default HeaderContext
