import { search } from '../lib/axios'

/**
 * Asynchronously retrieves a list of graphs based on the specified search term and filters.
 *
 * @async
 * @function getGraphs
 * @param {string} term - The search term.
 * @param {Object} selectedFilters - The selected filters for the search.
 * @returns {Promise<Array|null>} - An array of graphs or null if no graphs are found.
 */
export default async function getGraphs(term, selectedFilters) {
  const response = await search(term, selectedFilters)
  if (response.status === 204) {
    return null
  }

  const { bindings } = response.data.results
  const graphs =
    bindings.length > 0
      ? [...new Set(bindings.map((binding) => binding.graph.value))]
      : false
  return graphs
}
