import SearchSharpIcon from '@mui/icons-material/SearchSharp'
import {
  Autocomplete,
  Box,
  Chip,
  IconButton,
  Stack,
  createFilterOptions,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import filters from '../../config/filters.json'
import { getCards } from '../../lib/axios'
import { TextFieldRounded } from '../../styles/TextFields'
import getGraphs from '../../utils/getGraphs'
import { CardsLoaded, flatten } from '../../utils/utils'

/**
 * Loads and reformats the filters to be used for the Autocomplete feature.
 *
 * @function LoadFilters
 * @returns {Array} - An array of objects with the following properties:
 *   {string} category - The category of the filter.
 *   {string} value - The value of the filter.
 *   {number} id - The ID of the filter.
 *   {string} color - The color associated with the filter category.
 */
export function LoadFilters() {
  let filtersList = []
  Object.entries(filters).forEach((entries) => {
    if (entries[0] !== 'Mots-clés') {
      entries[1].Options.forEach((val, index) => {
        if (val !== 'Autre') {
          filtersList.push({
            category: entries[0],
            value: val,
            id: index,
            color: entries[1].Color,
          })
        }
      })
    } else {
      const flatArray = flatten(entries[1].Options)
      const transformedItems = flatArray.map((item, index) => ({
        category: entries[0],
        value: item.split('_').slice(-1)[0],
        id: index,
        color: entries[1].Color,
      }))
      filtersList = filtersList.concat(transformedItems)
    }
  })
  return filtersList
}

export default function SearchBar({ setInput, checked, setCards, setLoading }) {
  const [inputValue, setInputValue] = useState('')
  const [open, setOpen] = useState(false)
  const [filtersList, setFiltersList] = useState([])
  const handleOpen = (value) => {
    if (value.length > 0) {
      setOpen(true)
    }
  }
  const handleInputChange = async (value, newInputValue, reason) => {
    setInputValue(newInputValue)
    if (value !== undefined) {
      if (value.length > 0) {
        setOpen(true)
      }
    }
    if (reason === 'clear') {
      const graphs = await getGraphs(value, checked)
      if (!graphs) {
        setCards(await getCards())
      } else {
        setCards(await getCards(graphs))
      }
    }
  }
  useEffect(() => {
    setFiltersList(LoadFilters())
  }, [])

  const handleSearch = async (value) => {
    setLoading(true)
    const trimmedValue = value.trim()
    const allArraysEmpty = Object.values(checked).every(
      (arr) => arr.length === 0
    )
    if (trimmedValue || !allArraysEmpty) {
      const graphs = await getGraphs(trimmedValue, checked)
      const cards = await getCards(graphs)
      setCards(cards)
    } else {
      setCards(await getCards())
      setInput('')
    }
    setInput(`input=${trimmedValue}&`)
    CardsLoaded(setLoading)
  }

  const filterOptions = createFilterOptions({
    matchForm: 'start',
    trim: true,
    stringify: (option) => option.value,
  })

  return (
    <Stack spacing={2} direction="row">
      <Autocomplete
        fullWidth
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '30px',
        }}
        inputValue={inputValue}
        id="searchBar"
        data-test="searchBar"
        freeSolo
        options={filtersList}
        getOptionLabel={(option) => (option.value ? option.value : option)}
        open={open}
        onOpen={(event) => {
          handleOpen(event.target.value)
        }}
        onClose={() => setOpen(false)}
        onInputChange={(event, newInputValue, reason) => {
          handleInputChange(event.target.value, newInputValue, reason)
        }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            handleSearch(event.target.value)
            event.preventDefault()
          }
        }}
        filterOptions={filterOptions}
        renderOption={(renderprops, option) => (
          <Stack
            data-test={`searchOption-${option.category}-${option.value}`}
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            component="li"
            {...renderprops}
            key={`listItem-${option.id}-${option.value}`}
          >
            <Box>{option.value}</Box>
            <Chip
              label={option.category}
              sx={{ backgroundColor: option.color }}
            />
          </Stack>
        )}
        renderInput={(params) => (
          <TextFieldRounded
            {...params}
            id="Search_input"
            placeholder="Saisir un mot ou une phrase"
            InputLabelProps={{
              ...params.inputProps,
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <IconButton
                  data-test="searchButton"
                  onClick={() => {
                    handleSearch(inputValue)
                  }}
                >
                  <SearchSharpIcon />
                </IconButton>
              ),
            }}
          />
        )}
      />
    </Stack>
  )
}

SearchBar.propTypes = {
  checked: PropTypes.shape.isRequired,
  setCards: PropTypes.func.isRequired,
  setInput: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
}
