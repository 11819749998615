import { DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import ContactMd from '../config/texts/Contact.md'
import { ButtonSimple } from '../styles/Buttons'
import MarkdownText from './MarkdownText'
import { useSettings } from '../utils/settings'

export default function Contact() {
  const settings = useSettings();

  return (
    <div>
      <DialogTitle variant="h5">Contact</DialogTitle>
      <DialogContent data-test="contactDialog">
        <Stack spacing={2}>
          <Typography>
            <MarkdownText markdownFile={ContactMd} />
          </Typography>
          <ButtonSimple
            variant="contained"
            onClick={() => {
              window.open(`mailto:${settings?.contactEmail}`)
            }}
          >
            Écrivez-nous!
          </ButtonSimple>
        </Stack>
      </DialogContent>
    </div>
  )
}
