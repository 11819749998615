import { Stack } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import depthKeyCreator from '../../config/depthKey'
import CardSimple from '../../styles/Cards'
import { getTranslated } from '../../utils/utils'
import MetadataBreadcrumbs from './Breadcrumbs'
import { HeaderContext } from './HeaderContext'
import MetadataTabs from './Tabs'
import Title from './Title'

function getHeaderMetaData(metadata) {
  const output = {}
  metadata?.Header.fr_field_name.forEach((item, index) => {
    output[item] = getTranslated(metadata?.Header.object[index][0])
  })
  return output
}

export default function Header() {
  const {
    depth,
    graph,
    distribution,
    metadata,
    table,
    variable,
    dataset,
    sample,
    database,
    dataService,
    tabState,
    setTabState,
    listData,
    showEmpty,
    setShowEmpty,
  } = useContext(HeaderContext)
  const [title, setTitle] = useState()
  const [text, setText] = useState()
  const [depthKey, setDepthKey] = useState({})

  useEffect(() => {
    const output = getHeaderMetaData(metadata?.data)
    setDepthKey(depthKeyCreator(graph, dataset, distribution, table, variable, dataService, sample, database))
    setTitle(output?.HeaderTitle)
    setText(output?.HeaderType)
  }, [distribution, graph, metadata, table, variable, listData, dataService, dataset, sample, database])

  if (depth in depthKey) {
    if (listData === undefined) {
      if (depthKey[depth].tabs !== null) {
        depthKey[depth].tabs[depthKey[depth].list] = true
      }
    }

    console.log(depth);

    return (
      <Stack>
        <MetadataBreadcrumbs depth={depth} depthKey={depthKey} />
        <CardSimple variant="outlined">
          <Title
            text={text}
            name={title}
            graphid={graph}
            showEmpty={showEmpty}
            setShowEmpty={setShowEmpty}
          />
          <MetadataTabs
            depth={depth}
            depthKey={depthKey}
            tabState={tabState}
            setTabState={setTabState}
          />
        </CardSimple>
      </Stack>
    )
  }
}
