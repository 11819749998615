import { Container } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import AppBar from '../components/AppBar'
import Form from '../features/Form/Form'
import { Page } from '../styles/Styles'
import Unauthorized from './Unauthorized'

function DocumentPage(props) {
  const { url, session } = props
  if (session) {
    window.location.href = '/'
  }
  if (url.includes('new')) {
    return <Form />
  }
  if (url.includes('edit')) {
    return <Form />
  }
}

export default function Document() {
  const [session, setSession] = useState()
  const [logoutUrl, setLogoutUrl] = useState()
  const [permissions, setPermissions] = useState([])
  const [role, setRole] = useState()

  const checkRights = () => {
    const urlParams = new URL(window.location.toLocaleString()).searchParams
    const graph = urlParams.get('graph')
    const isGraphInObjects = permissions.some((obj) => obj.object === graph)
    return isGraphInObjects
  }

  useEffect(() => {
    getOrySession(setSession, setLogoutUrl, setPermissions, setRole)
  }, [])

  if (role === 'admin' || checkRights()) {
    return (
      <Page maxWidth={false} disableGutters>
        <AppBar session={session} logoutUrl={logoutUrl} />
        <Container maxWidth={false} sx={{ marginTop: '1%' }}>
          <DocumentPage url={window.location.href} access={session} />
        </Container>
      </Page>
    )
  }
  return <Unauthorized />
}

DocumentPage.propTypes = {
  url: PropTypes.string.isRequired,
  session: PropTypes.shape.isRequired,
}
