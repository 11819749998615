import DownloadSharpIcon from '@mui/icons-material/DownloadSharp'
import { Box, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { downloadGraph } from '../lib/axios'
import { IconButtonSimple } from '../styles/Buttons'

const FileSaver = require('file-saver')

export default function DownloadMenu(props) {
  const { indentifier } = props
  const [anchorElUser, setAnchorElUser] = useState(null)
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }
  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }
  const handleMenuItemClick = async (idDocument, filetype, type) => {
    const result = await downloadGraph(idDocument, filetype)
    const fileName = `${idDocument}.${filetype}`
    const blob = new Blob([result.data], { type: type })
    const file = new File([blob], fileName, { type: type })
    FileSaver.saveAs(file, fileName)
  }

  const settings = [
    { text: 'json', type: 'application/json' },
    { text: 'csv', type: 'text/csv ' },
    { text: 'xml', type: 'application/xml' },
  ]
  return (
    <Box>
      <Tooltip title="Télécharger les Metadonnées">
        <IconButtonSimple
          onClick={handleOpenUserMenu}
          data-test={`downloadDoc-${indentifier}`}
        >
          <DownloadSharpIcon />
        </IconButtonSimple>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        data-test={`downloadDoc-${indentifier}`}
        sx={{
          '& .MuiPaper-root': {
            boxShadow:
              'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          },
          '& .MuiMenu-list': {
            padding: '4px 0',
          },
          '& .MuiMenuItem-root': {},
        }}
      >
        {settings.map((item) => (
          <Box key={item.text}>
            <MenuItem
              key={item.text}
              data-test={item.text}
              onClick={() =>
                handleMenuItemClick(indentifier, item.text, item.type)
              }
            >
              <Typography>{item.text}</Typography>
            </MenuItem>
          </Box>
        ))}
      </Menu>
    </Box>
  )
}

DownloadMenu.propTypes = {
  indentifier: PropTypes.string.isRequired,
}
