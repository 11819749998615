import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { getCards } from '../../lib/axios'
import getGraphs from '../../utils/getGraphs'
import { CardsLoaded } from '../../utils/utils'

export default function ListOptions({
  options,
  searchName,
  label,
  checked,
  setChecked,
  setInput,
  setCards,
  setLoading,
}) {
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(!open)
  }
  const addFilter = (search, newChecked, value) => {
    if (newChecked[search].includes(value)) {
      newChecked[search].splice(newChecked[search].indexOf(value), 1)
    } else {
      newChecked[search].push(value)
    }
    return newChecked
  }
  const handleToggle = (search, value) => async () => {
    setLoading(true)
    const newChecked = { ...checked }
    const input = document.getElementById('searchBar').value
    const updatedChecked = addFilter(search, newChecked, value)
    setChecked(updatedChecked)
    const allArraysEmpty = Object.values(updatedChecked).every(
      (arr) => arr.length === 0
    )
    if (input || !allArraysEmpty) {
      setInput(`input=${document.getElementById('searchBar').value}&`)
      const graphs = await getGraphs(input, newChecked)
      const cards = await getCards(graphs)
      setCards(cards)
    } else {
      setCards(await getCards())
      setInput('')
    }
    CardsLoaded(setLoading)
  }
  function optionList(value, spacing, children) {
    const labelId = `checkbox-list-label-${value}`
    return (
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem key={value} disablePadding>
            <ListItemButton
              data-test={`filterOption${value}`}
              role={undefined}
              onClick={handleToggle(searchName, value)}
              dense
              sx={{ paddingLeft: spacing }}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  checked={checked[searchName].includes(value)}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItemButton>
          </ListItem>
        </List>
        {children}
      </Collapse>
    )
  }

  /**
   * Generates a view for rendering a list of options with nested structure.
   *
   * @param {Object|Array} optionsList - The list of options to be rendered.
   * @param {string} spacing - The spacing value for indentation.
   * @returns {Array} - The array of React elements representing the options view.
   */
  function optionsView(optionsList, spacing) {
    if (optionsList.constructor === Object) {
      return Object.keys(optionsList).map((value) => {
        if (optionsList[value].length !== 0) {
          return optionList(
            value,
            spacing,
            optionsView(optionsList[value], '24px')
          )
        }
        return optionList(value, spacing)
      })
    }
    return optionsList.map((value) => optionList(value, spacing))
  }
  const spacing = '12px'
  return (
    <List disablePadding sx={{ '&.MuiList-root': { marginTop: 0 } }}>
      <ListItemButton
        sx={{ paddingLeft: '4px', paddingRight: '4px' }}
        onClick={handleClick}
      >
        <ListItemText data-test={`filter${label}`} primary={label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Box sx={{ overflow: 'auto', maxHeight: '80vh' }}>
        {optionsView(options, spacing)}
      </Box>
      <Divider />
    </List>
  )
}

ListOptions.propTypes = {
  options: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  searchName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.shape.isRequired,
  setChecked: PropTypes.func.isRequired,
  setInput: PropTypes.func.isRequired,
  setCards: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
}
