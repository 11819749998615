import ExpandLessSharpIcon from '@mui/icons-material/ExpandLessSharp'
import { Container, Stack } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import AppBar from '../components/AppBar'
import Header from '../features/Metadata/Header'
import { HeaderContext } from '../features/Metadata/HeaderContext'
import TabSwitcher from '../features/Metadata/TabSwitcher'
import { TabSwitcherContext } from '../features/Metadata/TabSwitcherContext'
import {
  MetadataCSVListDistributions,
  MetadataCSVDataService,
  MetadataCSVListVariables,
  MetadataCSVListTables,
  MetadataCSVTable,
  MetadataCSVVariable,
  MetadataDataset,
  MetadataDistribution,
  MetadataCSVListDataService,
  MetadataCSVListDatabase,
  MetadataCSVDatabase,
  MetadataCSVListForeignKeys
} from '../lib/axios'
import { FABScrollUp } from '../styles/Buttons'
import { Page } from '../styles/Styles'

export default function Metadata() {
  const [showEmpty, setShowEmpty] = useState(false)
  const url = new URL(window.location.href)
  const graph = url.searchParams.get('graph')
  const distribution = url.searchParams.get('distribution')
  const dataset = url.searchParams.get('dataset')
  const table = url.searchParams.get('table')
  const variable = url.searchParams.get('variable')
  const dataService = url.searchParams.get('data_service')
  const database = url.searchParams.get('database')
  const sample = url.searchParams.get('sample')
  const depth = url.pathname.endsWith('/')
    ? url.pathname.slice(0, -1)
    : url.pathname
  const [scrollPosition, setScrollPosition] = useState(0)
  const [metadata, setMetadata] = useState()
  const [listData, setListData] = useState()
  const [secondaryListData, setSecondaryListData] = useState()
  const [, setName] = useState()
  const [tabState, setTabState] = useState(0)
  const handleScroll = () => {
    const position = window.scrollY
    setScrollPosition(position)
  }

  useEffect(() => {
    setTabState(0)

    window.addEventListener('scroll', handleScroll, { passive: true })
    const getMetadata = async () => {
      try {
        if (variable) {
          setMetadata(
            await MetadataCSVVariable(graph, dataset, database, sample, table, variable)
          )
          setName(variable)
        } else if (table) {
          setMetadata(await MetadataCSVTable(graph, dataset, sample, database, table))
          setListData(
            await MetadataCSVListVariables(graph, dataset, sample, database, table)
          )
          setSecondaryListData(await MetadataCSVListForeignKeys(graph, dataset, sample, database, table))
          setName(table)
        } else if (database) {
          setMetadata(await MetadataCSVDatabase(graph, dataset, sample, database))
          setListData(await MetadataCSVListTables(graph, dataset, sample, database))
          setName(database)
        } else if (dataService) {
          setMetadata(await MetadataCSVDataService(graph, dataset, distribution, dataService))
          setName(dataService)
        } else if (distribution) {
          setMetadata(await MetadataDistribution(graph, dataset, distribution))
          setListData(await MetadataCSVListDataService(graph, dataset, distribution))
          setName(distribution)
        } else {
          setMetadata(await MetadataDataset(graph, dataset))
          setListData(await MetadataCSVListDistributions(graph, dataset))
          setSecondaryListData(await MetadataCSVListDatabase(graph, dataset))
          setName(graph)
        }
      } catch (error) {
        console.log(error)
        // Write the error handling
      }
    }
    getMetadata()
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [graph, distribution, table, variable, dataService, database, dataset, sample])

  const providerValueHeader = useMemo(
    () => ({
      ...{
        depth,
        graph,
        distribution,
        metadata,
        dataService,
        database,
        dataset,
        sample,
        table,
        variable,
        tabState,
        setTabState,
        listData,
        secondaryListData,
        showEmpty,
        setShowEmpty,
      },
    }),
    [
      depth,
      graph,
      distribution,
      metadata,
      table,
      dataService,
      sample,
      database,
      dataset,
      variable,
      tabState,
      listData,
      secondaryListData,
      showEmpty,
    ]
  )

  const providerValueTabSwitcher = useMemo(
    () => ({
      ...{
        graph,
        distribution,
        table,
        variable,
        dataService,
        database,
        dataset,
        sample,
        tabState,
        depth,
        listData,
        secondaryListData,
        metadata,
        showEmpty,
      },
    }),
    [
      graph,
      distribution,
      table,
      variable,
      dataService,
      database,
      dataset,
      sample,
      tabState,
      depth,
      listData,
      secondaryListData,
      metadata,
      showEmpty,
    ]
  )

  return (
    <Page maxWidth={false}>
      <AppBar />
      <Container maxWidth={false} sx={{ marginTop: '2%' }}>
        <Stack spacing={2}>
          <HeaderContext.Provider value={providerValueHeader}>
            <Header />
          </HeaderContext.Provider>
          <TabSwitcherContext.Provider value={providerValueTabSwitcher}>
            <TabSwitcher />
          </TabSwitcherContext.Provider>
        </Stack>
      </Container>
      {scrollPosition > 100 ? (
        <FABScrollUp
          size="medium"
          color="secondary"
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
          }}
        >
          <ExpandLessSharpIcon />
        </FABScrollUp>
      ) : null}
    </Page>
  )
}
