import MailSharpIcon from '@mui/icons-material/MailSharp'
import {
  Box,
  CardHeader,
  Dialog,
  Grid,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import noSearchResults from '../assets/searchNoResults.png'
import Contact from '../components/Contact'
import SummaryCard from '../components/SummaryCard'
import filters from '../config/filters.json'
import FilterBar from '../features/Filters/FilterBar'
import SelectedFilters from '../features/Filters/SelectedFilters'
import SearchBar from '../features/Search/SearchBar'
import { getCards } from '../lib/axios'
import { ButtonSimple } from '../styles/Buttons'
import CardSimple from '../styles/Cards'
/**
 * The home/front page of the App
 * @returns {React.ReactElement<any, any>}
 */
export default function Home() {
  const [loading, setLoading] = useState(true)
  const [openContactDialog, setOpenContactDialog] = useState(false)
  const [cards, setCards] = useState()
  const [input, setInput] = useState('')
  const [checked, setChecked] = useState(() => {
    const listFilters = {}
    Object.keys(filters).forEach((element) => {
      listFilters[filters[element].SearchName] = []
    })
    return listFilters
  })

  const handleOpenContactDialog = () => {
    setOpenContactDialog(true)
  }

  const handleCloseContactDialog = () => {
    setOpenContactDialog(false)
  }

  useEffect(() => {
    const getCardsEffect = async () => {
      const response = await getCards()
      setCards(response)
    }
    getCardsEffect().then(setLoading(false))
  }, [])

  const showCards = () => {
    if (loading) {
      return (
        <Grid sx={{ width: '100%' }}>
          {[...Array(3)].map((e) => (
            <Box key={e}>
              <Skeleton
                variant="rounded"
                animation="wave"
                sx={{ borderRadius: '12px', height: '300px' }}
              />
              <br />
            </Box>
          ))}
        </Grid>
      )
    }

    if (cards?.data?.results?.bindings.length) {
      return cards.data.results.bindings.map((metadata) => (
        <SummaryCard
          key={metadata.identifier.value}
          metadata={metadata}
          input={input}
        />
      ))
    }
    return (
      <Grid
        container
        spacing={2}
        sx={{ minHeight: '50vh' }}
        direction="column"
        alignItems="center"
        display="flex"
        justifyContent="center"
        data-test="NoResultsBox"
      >
        <Box
          component="img"
          sx={{
            height: 80,
            width: 80,
          }}
          alt={
            <a
              href="https://www.flaticon.com/free-icons/no-results"
              title="no-results icons"
            >
              No-results icons created by Freepik - Flaticon
            </a>
          }
          src={noSearchResults}
        />

        <Typography
          sx={{ fontWeight: 'bold' }}
        >
          Aucun Résultats
        </Typography>
      </Grid>
    )
  }

  const showNumberCards = () => {
    if (loading) {
      return (
        <Typography
          variant="subtitle2"
          sx={{ fontStyle: 'oblique 10deg' }}
          data-test="resultsNumber"
        >
          ? Résultats
        </Typography>
      )
    }
    return (
      <Typography
        variant="subtitle2"
        sx={{ fontStyle: 'oblique 10deg' }}
        data-test="resultsNumber"
      >
        {cards?.data?.results?.bindings.length
          ? `${cards.data.results.bindings.length} Résultats`
          : null}
      </Typography>
    )
  }

  return (
    <Grid spacing={2} container sx={{ marginTop: '2%' }}>
      <Grid item xs={12}>
        <SearchBar {...{ setCards, setInput, checked, setLoading }} />
      </Grid>
      <Grid item xs={12}>
        <SelectedFilters
          {...{
            checked,
            setChecked,
            setInput,
            setCards,
            setLoading,
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <FilterBar
          {...{
            checked,
            setChecked,
            setInput,
            setCards,
            setLoading,
          }}
        />
      </Grid>
      <Grid item xs={9}>
        <Stack spacing={2}>
          <CardSimple variant="outlined">
            <CardHeader
              title={
                <>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      Bases de données
                    </Typography>
                    <Stack spacing={1} direction="row">
                      <Tooltip title="Contact">
                        <ButtonSimple
                          onClick={handleOpenContactDialog}
                          startIcon={<MailSharpIcon />}
                          data-test="ContactButton"
                          color="inherit"
                        >
                          Contacter-nous
                        </ButtonSimple>
                      </Tooltip>
                      <Dialog
                        open={openContactDialog}
                        onClose={handleCloseContactDialog}
                      >
                        <Contact />
                      </Dialog>
                    </Stack>
                  </Stack>
                  {showNumberCards()}
                </>
              }
            />
          </CardSimple>
          {showCards()}
        </Stack>
      </Grid>
    </Grid>
  )
}

