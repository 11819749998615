import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import depthKeyCreator from '../../config/depthKey'
import TranslatorFile from '../../config/inv_formats.json'
import {
  TableCellList,
  TableContainerCustom,
  TableRowHover,
} from '../../styles/Table'
import GenericCard from './GenericCard'
import { TabSwitcherContext } from './TabSwitcherContext'

const titles = {
  name: { label: 'Nom', show: false, align: 'left' },
  description: { label: 'Description', show: true, align: 'right' },
  datatype: { label: 'Type de données', show: true, align: 'right' },
  format: { label: 'Format', show: true, align: 'right' },
  medicalDataType: { label: 'Type de données', show: true, align: 'right' },
  accessURL: { label: "Procédure d'accès", show: true, align: 'right' },
  primaryKey: { label: 'Clé(s) primaire(s)', show: true, align: 'right' },
  schemaReference: { label: 'Nom de la table ciblée', show: true, align: 'right' },
  columnReference: { label: 'Nom de la clé primaire ciblée', show: true, align: 'left' },
  url: { label: 'URL cible', show: false, isDataShown: false, align: 'left' },
  ech: { label: 'Échantillon cible', show: false, isDataShown: false, align: 'left' },
  db: { label: 'Base de donnée cible', show: false, isDataShown: false, align: 'left' },
}

/**
 * Retrieves the translated text for the given code from the TranslatorFile.
 * If the translation is not found, returns 'N/A'.
 *
 * @param {string} code - The code for which translation is requested.
 * @returns {string} - The translated text or 'N/A' if not found.
 */
function getTranslatedAlt(code) {
  if (Object.hasOwn(TranslatorFile, code)) {
    return TranslatorFile[code]
  }
  return code === null ? 'N/A' : code
}

export default function TabSwitcher() {
  const {
    graph,
    distribution,
    table,
    variable,
    tabState,
    sample,
    database,
    dataset,
    dataService,
    depth,
    listData,
    secondaryListData,
    metadata,
    showEmpty,
  } = useContext(TabSwitcherContext)

  const [depthKey, setDepthKey] = useState({})
  useEffect(() => {
    setDepthKey(depthKeyCreator(graph, dataset, distribution, table, variable, dataService, sample, database))
  }, [graph, dataset, distribution, table, variable, dataService, sample, database])

  if (tabState === 1 && depth in depthKey && listData) {
    return (
      <TableContainerCustom component={Paper} variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              {Object.keys(listData.data[0]).filter((key) => titles[key]?.isDataShown !== false).map((key) => (
                <TableCell align={titles[key]?.align} key={key}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {titles[key]?.show ? titles[key].label : null}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {listData.data.map((data) => (
              <TableRowHover
                hover
                key={data}
                component={Link}
                to={
                  depthKey[depth].nextUrl
                    ? depthKey[depth].nextUrl.replace('null', (data.url ?? data.name))
                    : null
                }
              >
                {Object
                  .entries(data)
                  .filter(([key]) => titles[key]?.isDataShown !== false)
                  .map(([key, value]) => (
                    <TableCellList align={titles[key]?.align} key={value}>
                      {getTranslatedAlt(value)}
                    </TableCellList>
                  ))
                }
              </TableRowHover>
            ))}
          </TableBody>
        </Table>
      </TableContainerCustom>
    )
  }


  if (tabState === 2 && depth in depthKey) {
    return (
      secondaryListData
        ? (
          <TableContainerCustom component={Paper} variant="outlined">
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(secondaryListData.data[0]).filter((key) => titles[key]?.isDataShown !== false).map((key) => (
                    <TableCell align={titles[key]?.align} key={key}>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {titles[key]?.show ? titles[key].label : null}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {secondaryListData.data.map((data) => (
                  <TableRowHover
                    hover
                    key={data}
                    {...(depthKey[depth]?.secondaryNextUrl && {
                      component: Link,
                      to: (
                        depthKey[depth].secondaryNextUrl
                          ? depthKey[depth].secondaryNextUrl(data.ech, data.db)
                          : null
                      )
                    })}
                  >
                    {Object
                      .entries(data)
                      .filter(([key]) => titles[key]?.isDataShown !== false)
                      .map(([key, value]) => (
                        <TableCellList align={titles[key]?.align} key={value}>
                          {getTranslatedAlt(value)}
                        </TableCellList>
                      ))
                    }
                  </TableRowHover>
                ))}
              </TableBody>
            </Table>
          </TableContainerCustom>
        )
      : null
    );
  }

  return metadata
    ? Object
      .entries(metadata.data)
      .sort((a, b) => {
        if (a[0] === "Général") return -1;
        if (b[0] === "Général") return 1;
        return 0;
      })
      .map(([category, values]) => (
        <GenericCard
          key={category}
          category={category}
          metadata={values}
          showEmpty={showEmpty}
        />
      ))
    : null
}
