import HomeIcon from '@mui/icons-material/Home'
import { Breadcrumbs, Link, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

export default function MetadataBreadcrumbs({ depth, depthKey }) {
  return (
    <Breadcrumbs>
      <Link
        component={RouterLink}
        underline="hover"
        sx={{ display: 'flex', alignItems: 'center' }}
        color="inherit"
        to="/"
        data-test="Breadcrumb-HomePage"
      >
        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        Accueil
      </Link>
      {Object.entries(depthKey).map(([key, values]) => {
        const { text, param, url } = values
        if (param) {
          if (depth === key) {
            return (
              <Typography
                key={key}
                color="lightgray"
                data-test={`Breadcrumb-Disabled-${text} ${param}`}
              >{`${text} ${param}`}</Typography>
            )
          }
          return (
            <Link
              component={RouterLink}
              key={key}
              to={url}
              underline="hover"
              data-test={`Breadcrumb-${text} ${param}`}
            >
              {`${text} ${param}`}
            </Link>
          )
        }
        return null
      })}
    </Breadcrumbs>
  )
}

MetadataBreadcrumbs.propTypes = {
  depth: PropTypes.string.isRequired,
  depthKey: PropTypes.shape.isRequired,
}
