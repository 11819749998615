import { TableCell, TableContainer, TableRow } from '@mui/material'
import { styled } from '@mui/system'

// eslint-disable-next-line import/prefer-default-export
export const TableRowHover = styled(TableRow)({
  textDecoration: 'none',
  '&.MuiTableRow-root:hover': {
    backgroundColor: '#03628742',
  },
})

export const TableCellList = styled(TableCell)({ maxWidth: '30rem' })

export const TableContainerCustom = styled(TableContainer)({
  borderRadius: '12px',
})
