import { useEffect, useState } from 'react'
import { CardContent, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { getMetadataFilters } from '../../lib/axios'
import CardSimple from '../../styles/Cards'
import ListOptions from './Filter'

export default function FilterBar({
  checked,
  setChecked,
  setInput,
  setCards,
  setLoading,
}) {
  const [filters, setFilters] = useState({})

  useEffect(() => {
    const getFiltersEffect = async () => {
      const response = await getMetadataFilters()
      setFilters(response?.data)
    }
    getFiltersEffect()
  }, [])

  return (
    <CardSimple variant="outlined">
      <CardContent>
        <Stack>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            Filtres
          </Typography>
          <br />
          {Object.keys(filters).map((key) => (
            filters[key]
              ? (
                <ListOptions
                  options={filters[key].Options}
                  searchName={filters[key].SearchName}
                  label={key}
                  key={key}
                  checked={checked}
                  setChecked={setChecked}
                  setInput={setInput}
                  setCards={setCards}
                  setLoading={setLoading}
                />
              )
            : null
          ))}
        </Stack>
      </CardContent>
    </CardSimple>
  )
}

FilterBar.propTypes = {
  checked: PropTypes.shape.isRequired,
  setChecked: PropTypes.func.isRequired,
  setInput: PropTypes.func.isRequired,
  setCards: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
}
