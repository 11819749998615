import { createTheme, GlobalStyles, Stack, ThemeProvider } from '@mui/material'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import App from './App'
import Footer from './components/Footer'
import MarkdownPage from './components/MarkdownPage'
import Form from './pages/Form'
import Metadata from './pages/Metadata'
import PageNotFound from './pages/PageNotFound'
import { SettingsProvider, useSettings } from './utils/settings';
import getTheme from './styles/Theme'

import '@fontsource/caveat';
import '@fontsource/comfortaa';
import '@fontsource/eb-garamond';
import '@fontsource/lexend';
import '@fontsource/lobster';
import '@fontsource/lora';
import '@fontsource/maven-pro';
import '@fontsource/merriweather';
import '@fontsource/montserrat';
import '@fontsource/nunito';
import '@fontsource/oswald';
import '@fontsource/pacifico';
import '@fontsource/playfair-display';
import '@fontsource/roboto';
import '@fontsource/spectral';
import '@fontsource/syncopate';
import '@fontsource/ubuntu';

const rootElement = document.getElementById('root')

// const theme = createTheme(frFR)
const root = createRoot(rootElement)

function Root() {
  const settings = useSettings();

  return (
    <ThemeProvider theme={createTheme(getTheme(settings))}>
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: '#F2F7F9',
          },
        }}
      />
      <Stack>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="new" element={<Form />} />
            <Route path="edit" element={<Form />} />
            <Route path="about_us" />
            <Route
              path="legal_notice"
              element={<MarkdownPage markdownText={settings?.legalMentions} />}
            />
            <Route
              path="privacy_policy"
              element={<MarkdownPage markdownText={settings?.privacyPolicy} />}
            />
            <Route path="dataset" element={<Metadata />} />
            <Route path="distribution" element={<Metadata />} />
            <Route path="table" element={<Metadata />} />
            <Route path="variable" element={<Metadata />} />
            <Route path="database" element={<Metadata />} />
            <Route path="data-service" element={<Metadata />} />
            <Route path="ui/*" />
            <Route path="notfound" element={<PageNotFound />} />
            <Route path="*" element={<Navigate to="/notfound" />} />
          </Routes>
        </BrowserRouter>
        <Footer />
      </Stack>
    </ThemeProvider>
  )
};

function RootWrapper() {
  return (
    <SettingsProvider>
      <Root />
    </SettingsProvider>
  );
}


root.render(<RootWrapper />)
